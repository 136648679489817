import React, { Component, Suspense} from 'react'
import Fade from 'react-reveal/Fade';
import Typed from 'typed.js';
import {animateScroll as scroll } from 'react-scroll'
const Particles = React.lazy(() => import('react-particles-js'));

export class Banner extends Component {
  componentDidMount() {
    new Typed('.secondTitle', {
      strings: ['Software Engineer | Web Developer | Cloud Engineer'],
      typeSpeed: 25
    });
  }
  particleOpt = {
    particles: {
      number: {
        value: 130,
        density: {
          enable: true,
          value_area: 800
        }
      }
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 0.4,
        opacity_min: 0.1,
        sync: false
      }
    },
    polygon: {
      nb_sides: 5
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: 'grab'
        },
        onclick: {
          enable: true,
          mode: 'push'
        },
        resize: true
      }
    }
  }
  scrollToContact() {
    scroll.scrollToBottom();
  }
  render() {
    return (
      <Fade big duration={1500}>
        <div id='gradientContainer'>
        <Suspense fallback={<div>Loading...</div>}><Particles params={this.particleOpt} /></Suspense>
          <div className="main">
            <div className="mainContainer">
              <h1 className='mainTitle'>
                Hi,<br />
                I'm <strong className='boldStyle'>Syahmi</strong>,<br />
                Software Developer.
              </h1>
              
              <p className='codeText'>&lt;/&gt;</p>
            </div>
            <p className='secondTitle'></p>
            <input type="button" value="CONTACT ME" className="contactBtn" onClick={this.scrollToContact} to='/' />
          </div>
        </div>
      </Fade>
    )
  }
}

export default Banner
