import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTerminal } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import { Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "../../hamburger.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Navbar, Nav } from 'react-bootstrap';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* Render custom icon here */}
    <button className="item hamburger hamburger--spring">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  </a>
));

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      backgroundColor: 'transparent',
      position: 'absolute',
      display: 'none',
      borderBottom: 'none'
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  listenScrollEvent = e => {

    // if (window.scrollY > $('.headerMain').position().top - 100){
    //   this.setState({backgroundColor: '#0bc8ab'})
    //   this.setState({position: 'fixed'})
    // } else if(window.scrollY < $('.mainContainer').position().top ) {

    //   this.setState({backgroundColor: 'yellow'})
    //   this.setState({position: 'static'})
    // }

    if (window.scrollY > 0) {
      this.setState({ backgroundColor: '#252934', borderBottom: '2px solid #04c2c9', position: 'fixed', display: 'block' });
    } else {
      this.setState({ position: 'absolute', backgroundColor: 'rgba(11, 200, 171,0)', borderBottom: 'none', display: 'none' });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    $(function () {
      $('a').on('click', () => {
        $('a.active').removeClass('active');
        $(this).addClass('active');
      });

      var $hamburger = $(".hamburger");
      $hamburger.on("click", (e) => {
        $hamburger.toggleClass("is-active");
        $('.item').css('outline', 'none !important');
        if ($('.checkBoxCheck').prop('checked') === true) {
          $('#menu').css('transform', 'translate(200%, 0)');
          $('#menu').css('width', '0px');
          $('.checkBoxCheck').prop("checked", !$('.checkBoxCheck').prop("checked"));
          $('.hamburger-box').css('width', '40px');
        } else {
          $('#menu').css('width', '300px');
          $('#menu').css('transform', 'translate(0, 0)');
          $('.checkBoxCheck').prop("checked", !$('.checkBoxCheck').prop("checked"));

        }

      });
    });

  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollToAbout() {
    scroller.scrollTo('scrollToAbout', {
      duration: 700,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }

  scrollToPortfolio() {
    scroller.scrollTo('scrollToPortfolio', {
      duration: 700,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }

  scrollToSkills() {
    scroller.scrollTo('scrollToSkills', {
      duration: 700,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }

  scrollToAchievements() {
    scroller.scrollTo('scrollToAchievements', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }

  scrollToContact() {
    scroll.scrollToBottom();
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 991 });
  }

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <header style={{ backgroundColor: this.state.backgroundColor, top: '0px', position: this.state.position, borderBottom: this.state.borderBottom, transition: 'background-color 0.5s', opacity: '0.9', zIndex: 100 }} className="headerStyle">
        <div className='headerMain'>
          <Link className='logoStyle' onClick={this.scrollToTop} to='/'><FontAwesomeIcon icon={faTerminal} color={"#e31b6d"} />Syahmi</Link>

          <Navbar collapseOnSelect expand="lg" style={{ display: this.state.display }}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" as={CustomToggle} className="ml-auto" />
            {isDesktop ? (
              <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="ml-auto">
                  <Nav><span className='spanStyle'><Link className='linkStyle' onClick={this.scrollToAbout} to='/'>ABOUT</Link></span></Nav>
                  <Nav><span className='spanStyle'><Link className='linkStyle' onClick={this.scrollToPortfolio} to='/'>PORTFOLIO</Link></span></Nav>
                  <Nav><span className='spanStyle'><Link className='linkStyle' onClick={this.scrollToSkills} to='/'>SKILLS</Link></span></Nav>
                  <Nav><span className='spanStyle'><Link className='linkStyle' onClick={this.scrollToAchievements} to='/'>ACHIEVEMENTS</Link></span></Nav>
                  <Nav><span className='spanStyle2'><Link className='linkStyle' onClick={this.scrollToContact} to='/'>CONTACT</Link></span></Nav>

                </Nav>
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="ml-auto">
                  <Link className='linkStyle' onClick={this.scrollToAbout} to='/'><Nav><span className='spanStyle'>ABOUT</span></Nav></Link>
                  <Link className='linkStyle' onClick={this.scrollToPortfolio} to='/'> <Nav><span className='spanStyle'>PORTFOLIO</span></Nav></Link>
                  <Link className='linkStyle' onClick={this.scrollToSkills} to='/'><Nav><span className='spanStyle'>SKILLS</span></Nav></Link>
                  <Link className='linkStyle' onClick={this.scrollToAchievements} to='/'><Nav><span className='spanStyle'>ACHIEVEMENTS</span></Nav></Link>
                  <Link className='linkStyle' onClick={this.scrollToContact} to='/'> <Nav><span className='spanStyle2'>CONTACT</span></Nav></Link>
                </Nav>
              </Navbar.Collapse>
            )}
          </Navbar>
        </div>
      </header>
    )
  }
}

export default Header;
