import React, { Component, Suspense } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
// import Social from './components/layout/Social';
import Banner from './components/Banner'
import Header from './components/layout/Header';
// const Banner = React.lazy(() => import('./components/Banner'));

const About = React.lazy(() => import('./components/About.jsx'));
const Portfolio = React.lazy(() => import('./components/Portfolio.jsx'));
const Skills = React.lazy(() => import('./components/Skills.jsx'));
const Achievement = React.lazy(() => import('./components/Achievement.jsx'));
const Contact = React.lazy(() => import('./components/layout/Contact.jsx'));
const Footer = React.lazy(() => import('./components/layout/Footer.jsx'));

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">

          <Header />
          {/* <Social /> */}
          {/* <Route exact path='/' render={props => ( */}
            <React.Fragment>
              <Banner />
              <Suspense fallback={<div>Loading...</div>}>
                <About />
                <Portfolio />
                <Skills />
                <Achievement />
              </Suspense>
            </React.Fragment>
          {/* )} /> */}
          <Suspense fallback={<div>Loading...</div>}>
            <Contact />
            {/* <Particles params={particleOpt}/> */}
            <Footer />
          </Suspense>

        </div>
      </Router>
    );
  }

}

export default App;
